<template>
  <div class="list-roles-wrap">
    <CRow>
      <CCol class="col--padding">
        <CCard>
          <CCardBody>
            <div class="flex1">
              <label>{{$t('ROLE_MANAGEMENT_PAGE_TITLE')}}</label>
              <CButton class="btn btn-success btn-md btn-new" @click="goAdd">+ {{$t('ROLE_MANAGEMENT_PAGE_BUTTON_ADD')}}</CButton>
            </div>
            <table class="table table-striped table-drag">
              <thead class="thead-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{$t('ROLE_MANAGEMENT_PAGE_TABLE_COL_NAME')}}</th>
                  <th scope="col">{{$t('ROLE_MANAGEMENT_PAGE_TABLE_COL_LEVEL')}}</th>
                  <th scope="col" class="text-center">{{$t('ROLE_MANAGEMENT_PAGE_TABLE_COL_ACTION')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(role, index) in roles" :key="role.id">
                  <td>{{index + 1}}</td>
                  <td>{{role.name}}</td>
                  <td>{{role.level}}</td>
                  <td class="text-center">
                    <CButton @click="edit(role)" :disabled="role.level===0 ? true : false">{{$t('ROLE_MANAGEMENT_PAGE_TABLE_BODY_BUTTON_EDIT')}}</CButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import endpoints from '@/constants/endpoints';
import { mapState, mapActions } from 'vuex';
export default {
  components: {},
  data() {
    return {
      roles: [],
      limit: 10000,
      activePage: 1,
      pageInput: 1,
      total: null,
    };
  },
  async mounted() {
    const role = await this.$http.get(endpoints.getRoles);
    this.roles = role.data;
  },
  methods: {
    ...mapActions(['getSelectedRole']),
    edit(role) {
      this.getSelectedRole(role);
      this.$router.push(`roles/${role.id}/edit`);
    },
    goAdd() {
      this.$router.push('roles/create');
    },
  },
  name: 'ListRoles',
};
</script>

<style lang="scss">
.list-roles-wrap {
  $green: #4dc1a1;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .row {
    padding: 0;
    margin: 0;
    .col--padding {
      padding: 0 24px;
    }
  }
  .card-body {
    padding-top: 10px;
    padding-bottom: 10px;
    .flex1 {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      label {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0;
      }
      .btn-new {
        padding: 0;
        width: 170px;
        height: 32px;
        border-radius: 32px;
        background: #3b4b7e;
        border-color: unset;
        .pr--plus {
          svg {
            margin-top: 0;
          }
        }
      }
    }

    .table {
      border: 1px solid #d8dbe0;
      tr {
        color: #657186;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      thead {
        tr {
          font-weight: 600;
        }
      }
      td {
        color: #4d5666;
        font-size: 14px;
        padding: 8px;
        vertical-align: inherit;
        .btn {
          padding: 0;
        }
      }
      .blue {
        button {
          color: #5f55e7;
          font-size: 14px;
          line-height: 41px;
        }
      }
      .texts-wrap {
        display: flex;
        flex-direction: column;
        button {
          line-height: 10px;
        }
        span {
          background: #f2f52d;
          color: #000000;
          border-radius: 5px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
.text-center {
  text-align: center;
}
</style>
